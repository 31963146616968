@import '../../style.scss';
.consultants .item-list{
    margin-top: 30px;
    row-gap: 30px;
}
.consultants .item{
    padding: 48px 16px;
    position: relative;
    transition: var(--transition);
    max-width: 600px;
}
.consultants .item::after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 5px;
    background: #{$clr1};
    background: linear-gradient(45deg, #{$clr1} 0%, #{$clr2} 100%);
    transition: var(--transition);
}
.consultants .item:hover::after{
    width: 100%;
}
.consultants .item-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 28px;
}
.consultants .item-name{
    font-size: 25px;
}
.consultants .item-rating{
    margin-top: 35px;
}
.consultants .item-rating li{
    margin: 0 10px;
}

@media screen and (min-width: 768px){
    .consultants .section-t{
        text-align: center;
    }
    .consultants .section-t::before{
        left: 50%;
        transform: translateX(-50%);
    }
    .consultants .item-list{
        display: grid;
        grid-template-columns: auto; /* Change to auto to allow dynamic column width */
        justify-content: center; /* Center the items horizontally */
        column-gap: 30px;
    }
}

@media screen and (min-width: 992px){
    .consultants .item-list{
        grid-template-columns: auto;
    }
}