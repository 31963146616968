.about-content {
    row-gap: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .section-t::before{
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
  @media screen and (min-width: 992px) {
    .about-content {
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      column-gap: 60px;
      flex-direction: row; /* Revert back to row direction for larger screens */
    }
  }
  