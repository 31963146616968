@import '../../style.scss';
.header{
    min-height: 100vh;
    background: linear-gradient(#000000e6, #00000099), url("../../assets/images/AdobeStock_303004081.jpeg") right/cover no-repeat;
}
.header-content .text-lead{
    margin: 3.2rem 0 4.9rem 0;
    font-size: xx-large;
}
.header-title{
    font-size: 50px;
    font-weight: 900;
    line-height: 1.2;
    background: linear-gradient(45deg, #{$clr2}, #{$clr1});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header-btn{
    align-self: center!important;
}
.header-btn span{
    margin-left: 10px;
}

@media screen and (min-width: 600px){
    .header-title{
        font-size: 88px;
    }
    .header-content *{
        max-width: 920px;
    }
}